import React from "react";
import MenuData from "./MenuData";
import m1 from "../../images/Home/m1-1.jpg";
import m2 from "../../images/Home/m2.jpg";
import m3 from "../../images/Home/m3.jpg";
import "./OurMenu.css";
import "animate.css";
import TrackVisibility from "react-on-screen";

const OurMenu = () => {
  return (
    <div className="home-menu">
      {/* <h2>Flavors of </h2>
        <h1>Damascus Coffee</h1> */}
      <TrackVisibility once>
        {({ isVisible }) => (
          <div
            className={
              isVisible ? "animate__animated animate__slideInRight" : ""
            }
          >
            <h2>Flavors of </h2>
            <h1>Damascus Coffee</h1>
          </div>
        )}
      </TrackVisibility>
      <div className="home-menu-card">
        <MenuData img={m1} heading="Espresso" />
        <MenuData img={m2} heading="Drink" />
        <MenuData img={m3} heading="Sweet" />
      </div>
      <button className="fullMenuBtn-container">
        <a href="/our-menu" className="fullMenuBtn">
          See our Full Menu
        </a>
      </button>
    </div>
  );
};

export default OurMenu;
