// import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./LocDesc.css";
import TrackVisibility from "react-on-screen";
import "animate.css";

const LocDesc = () => {
  return (
    <div className="location">
      <Container>
        <Row>
          <Col xs={12} md={12} xl={6}>
            <div className="map-container">
              <iframe
                title="Store Location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1762.9677097551357!2d-64.81585592895117!3d46.0616469382571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ca0b9afe893c839%3A0x7d4d41cb6880482e!2sDamascus%20Coffee!5e0!3m2!1sen!2sca!4v1698356733156!5m2!1sen!2sca"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="google-map"
              ></iframe>
            </div>
          </Col>
          <Col xs={12} md={12} xl={6}>
            <TrackVisibility once>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__slideInRight" : ""
                  }
                >
                  <Row className="text-container">
                    <Col xs={12} md={9} xl={6}>
                      <h5>Visit Us</h5>
                      <p className="address">
                        C-710 Coverdale Road
                        <br />
                        Riverview, NB, E1B 3L1
                      </p>
                    </Col>
                    <Col xs={12} md={9} xl={6}>
                      <h5>Store Hours</h5>
                      <div className="date-container">
                        <p>
                          Monday <span className="wrap">7:00 AM - 4:00 PM</span>
                        </p>
                        <p>
                          Tuesday{" "}
                          <span className="wrap">7:00 AM - 4:00 PM</span>
                        </p>
                        <p>
                          Wednesday{" "}
                          <span className="wrap">7:00 AM - 4:00 PM</span>
                        </p>
                        <p>
                          Thursday{" "}
                          <span className="wrap">7:00 AM - 4:00 PM</span>
                        </p>
                        <p>
                          Friday <span className="wrap">7:00 AM - 4:00 PM</span>
                        </p>
                        <p>
                          Saturday{" "}
                          <span className="wrap">8:00 AM - 3:00 PM</span>
                        </p>
                        <p>
                          Sunday <span className="wrap">9:00 AM - 3:00 PM</span>
                        </p>
                      </div>
                      <button className="menuBtn-container">
                        <a href="/our-menu" className="menuBtn">
                          See our Menu
                        </a>
                      </button>
                    </Col>
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LocDesc;
