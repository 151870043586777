import React from "react";
import "./AboutBanner.css";
import { Col, Container, Row } from "react-bootstrap";
import coffee from "../../images/Story/coffee.png";
import bean from "../../images/Story/bean.png";
import cookie from "../../images/Story/cookie.png";
import takeout from "../../images/Story/takeout.png";
import TrackVisibility from "react-on-screen";
import "animate.css";

const AboutBanner = () => {
  return (
    <div className="about-banner">
      <TrackVisibility once>
        {({ isVisible }) => (
          <div className={isVisible ? "animate__animated animate__bounce" : ""}>
            <h5>OUR COFFEE</h5>
            <h1>
              What's special <span>about us</span>
            </h1>
          </div>
        )}
      </TrackVisibility>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={3}>
            <img src={coffee} alt="coffee cup" className="story-icons" />
            <h2>Comforting Drinks</h2>
            <p>
              Explore a diverse range of coffee options, from the classic
              Americano to indulgent flavored lattes, and even caffeine-free
              delights.
            </p>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <img src={bean} alt="coffee beans" className="story-icons" />
            <h2>Supreme Coffee Bean</h2>
            <p>
              Exceptional organic fair trade coffee beans, carefully crafted
              through special blending and local roasting for top-notch quality.
            </p>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <img src={cookie} alt="cookie" className="story-icons-up" />
            <h2>Delicious Sweets</h2>
            <p>
              Enjoy our signature oat cakes and cookies for a convenient and
              delicious snack.
            </p>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <img src={takeout} alt="takeout" className="story-icons-up" />
            <h2>Ready To Go</h2>
            <p>
              Short on time? No worries! You can order any of our beverages and
              food items for takeout.
            </p>
          </Col>
        </Row>
      </Container>
      <button className="locBtn-container">
        <a href="/location" className="locBtn">
          Visit us and Try our coffee today!
        </a>
      </button>
    </div>
  );
};

export default AboutBanner;
