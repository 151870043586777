import React from "react";
import Hero from "../../components/Home/Hero";
import Menu from "../../components/Order/Menu";
import GoToTop from "../../components/NavBar/GoToTop";

const Order = () => {
  return (
    <div>
      <Hero
        cName="hero"
        heroImg={require("../../images/Order/banner2.jpg")}
        title="Our Menu"
        tagline="Come and try our coffee!"
      />
      <Menu />
      <GoToTop />
    </div>
  );
};

export default Order;
