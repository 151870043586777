import React, { useEffect, useState } from "react";
import "./NavBar.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/NavBar/logo-white.png";
import logoD from "../../images/NavBar/logo.png";

const NavBar = (props) => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > window.innerHeight * 0.8) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container fluid>
        <Navbar.Brand className="logo-center d-lg-none">
          <Nav.Link className="logo-link" to="/" as={Link} eventKey="home">
            {scrolled ? (
              <img alt="logo" src={logoD} className="logo-dark" />
            ) : (
              <img alt="logo" src={logo} className="logo" />
            )}
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mt3">
          {scrolled ? (
            <span className="navbar-toggler-icon-dark" />
          ) : (
            <span className="navbar-toggler-icon" />
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className={
              scrolled
                ? "mx-auto align-items-center mt3 collapsed-bg-dark"
                : "mx-auto align-items-center mt3 collapsed-bg"
            }
          >
            <Nav.Item className="nav-menus">
              <Nav.Link
                eventKey="order"
                as={Link}
                to="/our-menu"
                className={scrolled ? "navbar-link-scrolled" : "navbar-link"}
                onClick={() => onUpdateActiveLink("order")}
              >
                Our Menu
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-menus">
              <Nav.Link
                as={Link}
                eventKey="story"
                to="/our-story"
                className={scrolled ? "navbar-link-scrolled" : "navbar-link"}
                onClick={() => onUpdateActiveLink("story")}
              >
                Our Story
              </Nav.Link>
            </Nav.Item>
            <Navbar.Brand className="logo-center d-none d-lg-block">
              <Nav.Link
                as={Link}
                eventKey="home"
                to="/"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                {scrolled ? (
                  <img alt="logo" src={logoD} className="logo-dark" />
                ) : (
                  <img alt="logo" src={logo} className="logo" />
                )}
              </Nav.Link>
            </Navbar.Brand>
            <Nav.Item className="nav-menus">
              <Nav.Link
                as={Link}
                eventKey="location"
                to="/location"
                className={scrolled ? "navbar-link-scrolled" : "navbar-link"}
                onClick={() => onUpdateActiveLink("location")}
              >
                Location
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-menus">
              <Nav.Link
                as={Link}
                eventKey="contact"
                to="/contact"
                className={scrolled ? "navbar-link-scrolled" : "navbar-link"}
                onClick={() => onUpdateActiveLink("contact")}
              >
                Contact Us
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
