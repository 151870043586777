import React from "react";
import Hero from "../../components/Home/Hero";
import LocDesc from "../../components/Location/LocDesc";
import GoToTop from "../../components/NavBar/GoToTop";

const Location = () => {
  return (
    <div>
      <Hero
        cName="hero"
        heroImg={require("../../images/Location/banner.jpg")}
        title="Our Location"
        tagline="Come see us!"
      />
      <LocDesc />
      <GoToTop />
    </div>
  );
};

export default Location;
