import './App.css';
import Home from './routes/HomePage/Home';
import NavBar from './components/NavBar/NavBar'
import { Route, Routes } from 'react-router-dom';
import Story from './routes/StoryPage/Story';
import Contact from './routes/ContactPage/Contact';
import Location from './routes/LocationPage/Location';
import Order from './routes/OrderPage/Order';
import Footer from './components/NavBar/Footer';

function App() {
  return (
    <div className='App'>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/our-story' element={<Story />} />
        <Route path='/location' element={<Location />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/our-menu' element={<Order />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
