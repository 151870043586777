import React from "react";
import Hero from "../../components/Home/Hero";
import OurStory from "../../components/Story/OurStory";
import AboutBanner from "../../components/Story/AboutBanner";
import GoToTop from "../../components/NavBar/GoToTop";

const Story = () => {
  return (
    <div>
      <Hero
        cName="hero"
        heroImg={require("../../images/Story/banner.jpg")}
        title="Our Story"
        tagline="Our coffee &"
      />
      <OurStory />
      <AboutBanner />
      <GoToTop />
    </div>
  );
};

export default Story;
