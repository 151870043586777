import React from "react";
import DescriptionData from "./DescriptionData";
import "./DescriptionData.css";
import l1 from "../../images/Home/l1.jpg";
import l2 from "../../images/Home/l2.jpg";
import "./LastDes.css";

const LastDes = () => {
  return (
    <div className="last-desc">
      <DescriptionData
        className="first-des-reverse"
        subheading="Hoping to"
        heading="See you soon!"
        tagline="Visit us and Try our coffee today"
        p1t="Phone Number"
        p1="(506) 855-4646"
        p2t="Location"
        p2="C-710 Coverdale Road
        Riverview, New Brunswick, E1B 3L1, Canada
        "
        img1={l1}
        img2={l2}
        button="locBtn-container"
        buttonText="Check our location"
      />
    </div>
  );
};

export default LastDes;
