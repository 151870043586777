import React from "react";
import "./Home.css";
import Hero from "../../components/Home/Hero";
import Description from "../../components/Home/Description";
import OurMenu from "../../components/Home/OurMenu";
import LastDes from "../../components/Home/LastDes";
import GoToTop from "../../components/NavBar/GoToTop";

const Home = () => {
  return (
    <div>
      <Hero
        cName="hero"
        heroImg={require("../../images/Home/banner1.jpg")}
        title="Damascus Coffee"
        tagline="Your local coffee shop"
        btnText="Our Menu"
        url="/our-menu"
        btnClass="show"
      />
      <Description />
      <OurMenu />
      <LastDes />
      <GoToTop />
    </div>
  );
};

export default Home;
