import React from "react";
import DescriptionData from "./DescriptionData";
import d1 from "../../images/Home/d1.jpg";
import d2 from "../../images/Home/d2.jpg";
import "./DescriptionData.css";

const Description = () => {
  return (
    <div>

        <DescriptionData
          className="first-des"
          subheading="Drink your"
          heading="Damascus Coffee"
          tagline="We make best coffee for you"
          p1="Original coffee house in Riverview serving premium and organic fair trade coffees. 
          Explore a diverse range of coffee options, from the classic Americano to indulgent flavored lattes, 
          and even caffeine-free delights. Come visit us today!"
          img1={d1}
          img2={d2}
          button="none"
        />
        {/* <DescriptionData /> */}
    </div>
  );
};

export default Description;
