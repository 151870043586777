import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "./ContactForm.css";

const ContactForm = () => {
  const formInitialDetails = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Submit Form");
  const [status, setStatus] = useState({});
  const [validated, setValidated] = useState(false);

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setButtonText("Submitting form...");
      let response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "Application/json;charset=utf-8",
        },
        body: JSON.stringify(formDetails),
      });
      setButtonText("Submit form");

      let result = await response.json();
      setFormDetails(formInitialDetails);
      if (result.code === 200) {
        setStatus({ success: true, message: "Message sent successfully!" });
      } else {
        setStatus({
          success: false,
          message: "Something went wrong. Please try again later.",
        });
      }
    }
  };
  return (
    <div className="contact-container">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="contact-form"
      >
        <Form.Group className="mb-3" controlId="validationCustomName">
          <FloatingLabel
            controlId="floatingInputName"
            label="Name"
            className="mb-3"
          >
            <Form.Control
              required
              type="text"
              placeholder="First and Last name"
              value={formDetails.name}
              onChange={(e) => onFormUpdate("name", e.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustomEmail">
          <FloatingLabel
            controlId="floatingInputEmail"
            label="Email address"
            className="mb-3"
          >
            <Form.Control
              required
              type="email"
              placeholder="name@example.com"
              value={formDetails.email}
              onChange={(e) => onFormUpdate("email", e.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustomPhone">
          <FloatingLabel
            controlId="floatingInputPhone"
            label="Phone number"
            className="mb-3"
          >
            <Form.Control
              required
              type="phone number"
              placeholder="000-000-0000"
              value={formDetails.phone}
              onChange={(e) => onFormUpdate("phone", e.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="validationCustomInput">
          <FloatingLabel
            controlId="floatingTextarea"
            label="Comments"
            className="mb-3"
          >
            <Form.Control
              required
              as="textarea"
              placeholder="Leave a comment here"
              value={formDetails.message}
              onChange={(e) => onFormUpdate("message", e.target.value)}
              style={{ height: "200px", resize: "none" }}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid comment.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <button className="contactBtn" type="submit">
          {buttonText}
        </button>
      </Form>
    </div>
  );
};

export default ContactForm;
