import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutImg from "../../images/Story/logor.jpg";
import "./OurStory.css";
import TrackVisibility from "react-on-screen";
import "animate.css";

const OurStory = () => {
  return (
    <div className="our-story">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility once>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__slideInLeft" : ""
                  }
                >
                  <span className="tagline">About us</span>

                  <h1>Roasted Coffee with Love</h1>
                  <p>
                    Whether it rains or shines, it's always a good time to drink
                    a cup of coffee.
                    <br />
                    At Damascus Coffee, we serve premium quality coffee as well
                    as delicious home-made sweets you'll never forget!
                  </p>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={aboutImg} alt="damascus coffee" className="story-image" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurStory;
