import React from "react";
import Hero from "../../components/Home/Hero";
import ContactForm from "../../components/Contact/ContactForm";
import GoToTop from "../../components/NavBar/GoToTop";

const Contact = () => {
  return (
    <div>
      <Hero
        cName="hero"
        heroImg={require("../../images/Contact/banner.jpg")}
        title="Contact Us"
        tagline="Any qustions?"
      />
      <ContactForm />
      <GoToTop />
    </div>
  );
};

export default Contact;
