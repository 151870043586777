import React from 'react'
import './MenuData.css'

const MenuData = (props) => {
  return (
    <div className='menu-card'>
        <div className='menu-card-image'>
            <img alt='menu imgs' src={props.img} />
        </div>
        <h4>{props.heading}</h4>
        <p>{props.text}</p>
    </div>
  )
}

export default MenuData