import React from "react";
import { Component } from "react";
import "./DescriptionData.css";
import TrackVisibility from "react-on-screen";

class DescriptionData extends Component {
  render() {
    return (
      <div className="description">
        <div className={this.props.className}>
          <div className="des-text">
            <TrackVisibility once>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__slideInLeft" : ""
                  }
                >
                  <h2>{this.props.subheading}</h2>
                  <h1>{this.props.heading}</h1>
                  <h5>{this.props.tagline}</h5>
                  <h4>{this.props.p1t}</h4>
                  <p>{this.props.p1}</p>
                  <h4>{this.props.p2t}</h4>
                  <p>{this.props.p2}</p>
                  <button className={this.props.button}>
                    <a href="/location" className="locBtn">
                      {this.props.buttonText}
                    </a>
                  </button>
                </div>
              )}
            </TrackVisibility>
          </div>
          {/* <div className="des-text">
            <h2>{this.props.subheading}</h2>
            <h1>{this.props.heading}</h1>
            <h5>{this.props.tagline}</h5>
            <h4>{this.props.p1t}</h4>
            <p>{this.props.p1}</p>
            <h4>{this.props.p2t}</h4>
            <p>{this.props.p2}</p>
            <button className={this.props.button}>
              <a href="/location" className="locBtn">
                {this.props.buttonText}
              </a>
            </button>
          </div> */}
          <div className="image">
            <img alt="img" src={this.props.img1} />
            <img alt="img" src={this.props.img2} />
          </div>
        </div>
      </div>
    );
  }
}

export default DescriptionData;
