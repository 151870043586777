import React from 'react'
import './Hero.css'
import 'animate.css'

const Hero = (props) => {
  return (
    <div className={props.cName}>
        <img alt='hero' src={props.heroImg} className='hero-img' />
        <div className='hero-text'>
            <h5>{props.tagline}</h5>
            <h1>{props.title}</h1>
            <h2>{props.subtitle}</h2>
            <p>{props.text}</p>
            <a href={props.url} className={props.btnClass}>
                {props.btnText}
            </a>
        </div>
    </div>
  )
}

export default Hero