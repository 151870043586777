import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import p1 from '../../images/Order/1.jpg'
import p2 from '../../images/Order/2-3.jpg'
import p3 from '../../images/Order/3.jpg'
import './Menu.css'

const Menu = () => {
  return (
    <div>
        <Container className='menu-container'>
            <Row>
                <Col xs={12} md={6}>
                    <img src={p1} alt='page1' className='menu-image'/>
                </Col>
                <Col xs={12} md={6}>
                    <img src={p2} alt='page2' className='menu-image' />
                </Col>
                <Col xs={12} md={6}>
                    <img src={p3} alt='page3' className='menu-image' />
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Menu